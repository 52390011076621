<template>
    <div class="detail">
        <headers></headers>
        <main class="main">
            <i @click="back" class="back">返回</i>
            <template v-if="list.length">
                <div>
                    <h5 v-for="(item, i) in list" :key="i" :class="[index == i ? 'current' : '']" @click="select(i)">
                        {{ item.name }}
                    </h5>
                </div>
                <div>
                    <div>
                        <section class="item">
                            <h1>{{ detail.name }}</h1>
                            <h2>{{ detail.title }}</h2>
                        </section>
                        <section class="item">
                            <h4>一、基本信息</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <!--<th>学校名称</th>
                                    <th>报告名称</th>-->
                                        <th>测试量表</th>
                                        <th>测评人数</th>
                                        <th>测评日期</th>
                                        <th>生成时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!--<td>丰顺县大同学校</td>
                                    <td>丰顺县大同学校2022春季全校心理健康筛查（最新版）</td>-->
                                        <td>{{ detail.cslb }}</td>
                                        <td>{{ detail.cprs }}</td>
                                        <td>{{ detail.cprq }}</td>
                                        <td>{{ detail.scsj }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                        <section class="item">
                            <h4>二、测验简介</h4>
                            <p>{{ detail.cyjj }}</p>
                        </section>
                        <section class="item">
                            <h4>三、团队平均状态</h4>
                            <div class="chart">
                                <detailBar :payload="detail.tdpjzt" />
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>项目</th>
                                        <th>平均值</th>
                                        <th>标准差</th>
                                        <th>最大值</th>
                                        <th>最小值</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>学习焦虑</td>
                                        <td>{{ detail.xxjl_pjz }}</td>
                                        <td>{{ detail.xxjl_bzc }}</td>
                                        <td>{{ detail.xxjl_zdz }}</td>
                                        <td>{{ detail.xxjl_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>社会焦虑</td>
                                        <td>{{ detail.sjjl_pjz }}</td>
                                        <td>{{ detail.sjjl_bzc }}</td>
                                        <td>{{ detail.sjjl_zdz }}</td>
                                        <td>{{ detail.sjjl_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>孤独倾向</td>
                                        <td>{{ detail.gdqx_pjz }}</td>
                                        <td>{{ detail.gdqx_bzc }}</td>
                                        <td>{{ detail.gdqx_zdz }}</td>
                                        <td>{{ detail.gdqx_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>自责倾向</td>
                                        <td>{{ detail.zzqx_pjz }}</td>
                                        <td>{{ detail.zzqx_bzc }}</td>
                                        <td>{{ detail.zzqx_zdz }}</td>
                                        <td>{{ detail.zzqx_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>过敏倾向</td>
                                        <td>{{ detail.gmqx_pjz }}</td>
                                        <td>{{ detail.gmqx_bzc }}</td>
                                        <td>{{ detail.gmqx_zdz }}</td>
                                        <td>{{ detail.gmqx_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>身体症状</td>
                                        <td>{{ detail.stzz_pjz }}</td>
                                        <td>{{ detail.stzz_bzc }}</td>
                                        <td>{{ detail.stzz_zdz }}</td>
                                        <td>{{ detail.stzz_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>恐怖倾向</td>
                                        <td>{{ detail.kbqx_pjz }}</td>
                                        <td>{{ detail.kbqx_bzc }}</td>
                                        <td>{{ detail.kbqx_zdz }}</td>
                                        <td>{{ detail.kbqx_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>冲动倾向</td>
                                        <td>{{ detail.cdqx_pjz }}</td>
                                        <td>{{ detail.cdqx_bzc }}</td>
                                        <td>{{ detail.cdqx_zdz }}</td>
                                        <td>{{ detail.cdqx_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>效度量表</td>
                                        <td>{{ detail.xdlb_pjz }}</td>
                                        <td>{{ detail.xdlb_bzc }}</td>
                                        <td>{{ detail.xdlb_zdz }}</td>
                                        <td>{{ detail.xdlb_zxz }}</td>
                                    </tr>
                                    <tr>
                                        <td>总评</td>
                                        <td>{{ detail.zp_pjz }}</td>
                                        <td>{{ detail.zp_bzc }}</td>
                                        <td>{{ detail.zp_zdz }}</td>
                                        <td>{{ detail.zp_zxz }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                        <section class="item">
                            <h4>四、人员分布</h4>
                            <div class="chart">
                                <bar :payload="detail.ryfb" />
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>维度名称</th>
                                        <th>状态名称</th>
                                        <th>分布</th>
                                        <th>预警比例</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowspan="3">学习焦虑</td>
                                        <td>焦虑程度较低</td>
                                        <td>{{ detail.xxjl_x }}</td>
                                        <td rowspan="3">{{ detail.xxjl_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>焦虑程度一般</td>
                                        <td>{{ detail.xxjl_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>焦虑程度较高</td>
                                        <td>{{ detail.xxjl_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">社交焦虑</td>
                                        <td>焦虑程度较低</td>
                                        <td>{{ detail.sjjl_x }}</td>
                                        <td rowspan="3">{{ detail.sjjl_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>焦虑程度一般</td>
                                        <td>{{ detail.sjjl_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>焦虑程度较高</td>
                                        <td>{{ detail.sjjl_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">孤独倾向</td>
                                        <td>孤独倾向较弱</td>
                                        <td>{{ detail.gdqx_x }}</td>
                                        <td rowspan="3">{{ detail.gdqx_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>孤独倾向一般</td>
                                        <td>{{ detail.gdqx_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>孤独倾向较强</td>
                                        <td>{{ detail.gdqx_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">自责倾向</td>
                                        <td>自责倾向较弱</td>
                                        <td>{{ detail.zzqx_x }}</td>
                                        <td rowspan="3">{{ detail.zzqx_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>自责倾向一般</td>
                                        <td>{{ detail.zzqx_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>自责倾向较强</td>
                                        <td>{{ detail.zzqx_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">过敏倾向</td>
                                        <td>过敏倾向较弱</td>
                                        <td>{{ detail.gmqx_x }}</td>
                                        <td rowspan="3">{{ detail.gmqx_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>过敏倾向一般</td>
                                        <td>{{ detail.gmqx_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>过敏倾向较强</td>
                                        <td>{{ detail.gmqx_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">身体症状</td>
                                        <td>身体症状正常</td>
                                        <td>{{ detail.stzz_s }}</td>
                                        <td rowspan="3">{{ detail.stzz_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>身体症状一般</td>
                                        <td>{{ detail.stzz_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>身体症状明显</td>
                                        <td>{{ detail.stzz_x }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">恐怖倾向</td>
                                        <td>恐怖倾向较弱</td>
                                        <td>{{ detail.kbqx_x }}</td>
                                        <td rowspan="3">{{ detail.kbqx_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>恐怖倾向一般</td>
                                        <td>{{ detail.kbqx_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>恐怖倾向较强</td>
                                        <td>{{ detail.kbqx_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">冲动倾向</td>
                                        <td>冲动倾向较弱</td>
                                        <td>{{ detail.cdqx_x }}</td>

                                        <td rowspan="3">{{ detail.cdqx_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>冲动倾向一般</td>
                                        <td>{{ detail.cdqx_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>冲动倾向较强</td>
                                        <td>{{ detail.cdqx_s }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="2">效度量表</td>
                                        <td>有参考价值</td>
                                        <td>{{ detail.xdlb_s }}</td>
                                        <td rowspan="2">{{ detail.xdlb_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>真实性不大</td>
                                        <td>{{ detail.xdlb_x }}</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3">总评</td>
                                        <td>正常</td>
                                        <td>{{ detail.zp_s }}</td>
                                        <td rowspan="3">{{ detail.zp_yjbl || 0 }}</td>
                                    </tr>
                                    <tr>
                                        <td>心理状态欠佳</td>
                                        <td>{{ detail.zp_z }}</td>
                                    </tr>
                                    <tr>
                                        <td>心理状态较差</td>
                                        <td>{{ detail.zp_x }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>
            </template>
            <div v-else class="empty">暂无数据</div>
        </main>
    </div>
</template>
<script>
    import detailBar from '../components/detailBar';
    import bar from '../components/bar';
    import headers from '../components/head';
    import { mapState } from 'vuex';
    export default {
        components: { bar, detailBar, headers },
        computed: { ...mapState(['type', 'titleName', 'historyLength']) },
        data: () => ({
            list: '',
            index: 0,
            detail: '',
            isTrue: false
        }),
        async mounted() {
            const hash = window.location.hash;
            if (/detail/.test(hash)) {
                this.isTrue = true;
            }
            this.list = await this.$http.get_school_data({
                type: this.historyLength == 3 ? 'town' : 'area',
                name: this.titleName
            });
            this.detail = this.list[this.index];
            console.log(this.list);
        },
        methods: {
            select(i) {
                this.index = i;
                this.detail = this.list[this.index];
            },
            back() {
                this.$router.push({ path: '/' });
            }
        }
    };
</script>
<style lang="less">
    .detail {
        background: #0a1842 url('../assets/bg.png') no-repeat center center;
        background-size: 100% 100%;
        box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        height: 100vh;

        .main {
            display: flex;
            justify-content: space-between;
            height: calc(100vh - 6vh - 1.2rem);
            padding: 20/100rem;
            position: relative;

            i.back {
                width: 60/100rem;
                height: 60/100rem;
                font-style: normal;
                font-size: 18/100rem;
                color: #fff;
                background: rgba(0, 194, 228, 1);
                border-radius: 80px;
                margin-right: 20/100rem;
                position: absolute;
                bottom: 200/100rem;
                right: 20/100rem;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 999;
                cursor: pointer;
                //animation: packet 5s infinite;
            }

            > div {
                width: 20vw;
                height: 100%;
                padding: 20/100rem;
                box-sizing: border-box;
                background: rgba(44, 160, 249, 0.1);
                h1,
                h2 {
                    text-align: center;
                }
                h1 {
                    font-size: 28/100rem;
                    color: #fff;
                }
                h2 {
                    font-size: 20/100rem;
                    color: #fff;
                    margin: 20/100rem 0 50/100rem;
                }
                h4 {
                    font-size: 18/100rem;
                    color: #fff;
                    line-height: 60/100rem;
                }
                h5 {
                    font-size: 18/100rem;
                    color: #fff;
                    line-height: 40/100rem;
                }
                p {
                    font-size: 14/100rem;
                    color: #fff;
                }

                &:nth-of-type(1) {
                    padding: 0;
                    overflow-y: scroll;
                    > div {
                        height: 90%;
                    }
                    &::-webkit-scrollbar {
                        width: 10/100rem;
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10/100rem;
                        -webkit-box-shadow-: inset 0 0 5px #2a7af5;
                        background: #4d93ff;
                    }

                    &::-webkit-scrollbar-track {
                        //border-radius: 10px;
                        background: rgba(77, 147, 255, 0.2);
                    }
                    > h5 {
                        font-size: 14/100rem;
                        padding: 20/100rem;
                        line-height: 40/100rem;
                        border-bottom: 1px dashed rgba(44, 160, 249, 0.3);
                        cursor: pointer;
                    }
                    > h5.current {
                        background: #4d93ff;
                    }
                }

                &:nth-of-type(2) {
                    width: 77vw;
                    padding: 20/100rem;
                    box-sizing: border-box;

                    overflow: hidden;
                    > div {
                        height: 100%;
                        overflow-y: scroll;
                    }
                    &::-webkit-scrollbar {
                        width: 10/100rem;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10/100rem;
                        -webkit-box-shadow-: inset 0 0 5px #2a7af5;
                        background: #4d93ff;
                    }

                    &::-webkit-scrollbar-track {
                        //border-radius: 10px;
                        background: rgba(77, 147, 255, 0.2);
                    }
                    .item {
                        width: 100%;
                        margin-bottom: 40/100rem;
                    }
                    .chart {
                        width: 99%;
                        height: 450/100rem;
                    }
                    table {
                        font-size: 14/100rem;
                        color: #fff;
                        width: 100%;
                        margin: auto;
                        padding-top: 0;
                        font-size: 14/100rem;
                        color: #fff;

                        th {
                            height: 60/100rem;
                            font-size: 16/100rem;
                        }

                        td {
                            height: 40/100rem;
                        }

                        th,
                        td {
                            background-color: #0c1e4a;
                            border: 1px solid #4d93ff;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .empty {
            font-size: 20/100rem;
            text-align: center;
            margin-top: 50/100rem;
            color: #fff;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            background: transparent !important;
        }
    }

    @keyframes packet {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1);
        }
        52% {
            transform: scale(1.4);
        }
        54% {
            transform: scale(0.9);
        }
        56% {
            transform: scale(1.4);
        }
        58% {
            transform: scale(0.9);
        }
        60% {
            transform: scale(1.4);
        }
        62% {
            transform: scale(0.9);
        }
        64% {
            transform: scale(1.4);
        }
        66% {
            transform: scale(0.9);
        }
        68% {
            transform: scale(1.4);
        }
        70% {
            transform: scale(0.9);
        }
        72% {
            transform: scale(1);
        }
    }
</style>
