<template>
    <div class="category" ref="category"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        props: ['payload'],
        watch: {
            payload: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        computed: { ...mapState(['city']) },
        name: 'category',
        data: () => ({}),
        methods: {
            init(payload) {
                console.log(payload);
                let title = [],
                    val = [];
                payload &&
                    payload.forEach(item => {
                        title.push(item.name);
                        val.push(item.value);
                    });

                const pieEcharts = this.$echarts.init(this.$refs.category);
                pieEcharts.setOption(
                    {
                        ...option,
                        yAxis: { ...option.yAxis, data: title },
                        series: [{ ...option.series[0], data: val }]
                    },
                    true
                );
                setTimeout(() => {
                    pieEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    pieEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .category {
        width: 100%;
        height: 100%;
    }
</style>
