<template>
    <div class="category" ref="category"></div>
</template>
<script type="text/javascript">
    import option from './config/options';
    import { mapState } from 'vuex';
    export default {
        props: ['payload'],
        watch: {
            payload: {
                handler(o) {
                    this.$nextTick(() => {
                        this.init(o);
                    });
                },
                immediate: true,
                deep: true
            }
        },
        computed: { ...mapState(['city']) },
        name: 'category',
        data: () => ({}),
        methods: {
            init(payload) {
                console.log(payload, 'dd');
                const title = [],
                    val = [];

                payload &&
                    payload.value.forEach((item, index) => {
                        item.type = 'bar';
                        item.barWidth = '10%';
                        item.label = {
                            show: true,
                            position: 'top',
                            textStyle: {
                                color: '#fff', //更改坐标轴文字颜色
                                fontSize: 0.14 + 'rem', //更改坐标轴文字大小
                                marginBotton: 10
                            }
                            //formatter: '{c} %'
                            //formatter: params => {
                            //    //核心部分 formatter 可以为字符串也可以是回调
                            //    if (params.value) {
                            //        //如果当前值存在则拼接
                            //        return `约 ${params.value} `;
                            //    } else {
                            //        //否则返回个空
                            //        return '';
                            //    }
                            //}
                        };

                        //payload.name.forEach(items => {
                        //    title.push({ name: index == 0 ? `${items}低` : index == 1 ? `${items}中` : `${items}高` });
                        //});

                        //title.forEach(element => (item.name = element.name));
                    });

                const pieEcharts = this.$echarts.init(this.$refs.category);
                payload &&
                    pieEcharts.setOption(
                        {
                            ...option,
                            xAxis: { ...option.xAxis, data: payload.name },
                            series: payload.value
                        },
                        true
                    );
                setTimeout(() => {
                    pieEcharts.resize();
                }, 300);
                window.addEventListener('resize', () => {
                    pieEcharts.resize();
                });
            }
        }
    };
</script>
<style lang="less" scoped>
    .category {
        width: 100%;
        height: 100%;
    }
</style>
