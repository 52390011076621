import { Autoplay } from 'swiper';

export default {
    color: ['#00C2E4'],
    title: {
        //text: '已接入系统学校', // 主标题
        y: '10px',
        x: '10px',
        textStyle: {
            color: '#fff'
        }
    },
    legend: {
        show: false,
        left: 'right',
        orient: 'vertical',
        textStyle: {
            color: '#fff'
        },
        itemWidth: 10,
        itemHeight: 10
    },

    yAxis: {
        type: 'category',
        data: ['梅江区区', '梅县区', '兴宁市', '平远县区', '蕉岭县'],
        inverse: true,
        axisLabel: {
            show: true,
            interval: 0,
            rotate: 0,
            interval: 0,
            lineHeight: 12,
            textStyle: {
                color: '#fff', //更改坐标轴文字颜色
                fontSize: 0.14 + 'rem' //更改坐标轴文字大小
            }
        },
        axisLine: {
            show: true // 轴线
        },
        axisTick: {
            //show: true // 刻度线
        }
    },
    xAxis: {
        type: 'value',
        splitLine: {
            show: false,
            //网格线
            lineStyle: {
                type: 'solid' //设置网格线类型 dotted：虚线 solid:实线
            }
        },
        scale: true,
        axisLabel: {
            show: true,
            textStyle: {
                color: '#fff' //更改坐标轴文字颜色
                //fontSize: 0.18 + 'rem', //更改坐标轴文字大小
            }
        },
        axisLine: {
            show: true // 轴线
        },
        axisTick: {
            //show: true // 刻度线
        }
    },
    grid: {
        left: '0',
        top: '15',
        right: '5',
        bottom: '20',
        containLabel: true
    },
    tooltip: {
        show: true, // 是否展示提示框组件,默认true
        backgroundColor: 'RGBA(2, 120, 231, 0.8)', //设置背景颜色
        textStyle: {
            color: 'white' //设置文字颜色
        },
        borderColor: 'RGBA(2, 120, 231, 0.8)', //设置边框颜色
        trigger: 'axis',
        axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        }
    },

    series: [
        {
            name: '数量',
            data: [20, 2, 15, 8],
            type: 'bar',
            //center: ['50%', '80%'],
            barWidth: '25%' //柱图宽度
            //label: {
            //    show: true,
            //    position: 'top',
            //    textStyle: {
            //        color: '#00C2E4', //更改坐标轴文字颜色
            //        fontSize: 0.14 + 'rem', //更改坐标轴文字大小
            //        marginBotton: 10,
            //    },
            //    formatter: params => {
            //        //核心部分 formatter 可以为字符串也可以是回调
            //        if (params.value) {
            //            //如果当前值存在则拼接
            //            return `约 ${params.value} 万元`;
            //        } else {
            //            //否则返回个空
            //            return '';
            //        }
            //    },
            //},
        }
    ]
};
